import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"
import styled from "styled-components"; 

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
import ArticleSidebar from "../../components/content/ArticleSidebar"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import SampleItineraries from "../../components/content/SampleItineraries"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
import '../../styles/adventure.css'
import ecotourism_video_still from '../../images/sidebar_video_ecotourism.jpg'; 
import itinerary_safari_1 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-1.jpg'; 
import itinerary_safari_2 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-2.jpg'; 
import itinerary_safari_3 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-3.jpg'; 
import itinerary_safari_4 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-4.jpg'; 
import itinerary_safari_5 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-5.jpg'; 
import itinerary_safari_6 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-6.jpg'; 
import itinerary_safari_7 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-7.jpg'; 
import itinerary_safari_8 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-8.jpg'; 
import itinerary_safari_9 from '../../images/sampleitinerary_carousels/adventure/adventure-safari-9.jpg'; 
import itinerary_peru_1 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-1.jpg'; 
import itinerary_peru_2 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-2.jpg'; 
import itinerary_peru_3 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-3.jpg'; 
import itinerary_peru_4 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-4.jpg'; 
import itinerary_peru_5 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-5.jpg'; 
import itinerary_peru_6 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-6.jpg'; 
import itinerary_peru_7 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-7.jpg'; 
import itinerary_peru_8 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-8.jpg'; 
import itinerary_peru_9 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-9.jpg'; 
import itinerary_peru_10 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-10.jpg'; 
import itinerary_peru_11 from '../../images/sampleitinerary_carousels/adventure/adventure-peru-11.jpg'; 
import itinerary_uncruise_vid from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-vid.jpg'; 
import itinerary_uncruise_ship_1 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-1.jpg'; 
import itinerary_uncruise_ship_2 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-2.jpg'; 
import itinerary_uncruise_ship_3 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-3.jpg'; 
import itinerary_uncruise_ship_4 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-4.jpg'; 
import itinerary_uncruise_ship_5 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-5.jpg'; 
import itinerary_uncruise_ship_6 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-ship-6.jpg'; 
import itinerary_uncruise_land_1 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-1.jpg'; 
import itinerary_uncruise_land_2 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-2.jpg'; 
import itinerary_uncruise_land_3 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-3.jpg'; 
import itinerary_uncruise_land_4 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-4.jpg'; 
import itinerary_uncruise_land_5 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-5.jpg'; 
import itinerary_uncruise_land_6 from '../../images/sampleitinerary_carousels/adventure/adventure-uncruise-land-6.jpg'; 




const ExpandButton = styled(Button)`
    padding:5px 15px; 
    border:0px; 
    background-color:transparent; 
    &:hover,
    &:active{ 
        background-color:transparent; 
        border:0px; 
    }
`;

const VideoButton = styled(Button)`
    padding:0px; 
    border:0px; 
    background-color:transparent; 
    &:hover,
    &:active{ 
        background-color:transparent; 
        border:0px; 
    }
`;


const Adventure = () => {
    const pageData = {
                        headline:{
                            text:<><span className="hidden-xs hidden-sm hidden-md">Make Your Vacation an Adventure</span><span className="hidden-lg hidden-xl hidden-xxl">Adventure Travel</span></>,
                            iframe:"https://storage.googleapis.com/outward-website-static-resources/page-headings/adventure/AdventureHero.html",
                            hero_height:'450',
                            id:'Adventure'
                        },
                        sectionnav:{section:'vacations'},
                        seo:{
                            title:'Make Your Vacation an Adventure',
                            description:'Seek out the new and the novel, going where few others have trod. Planet Earth is a big, beautiful place—there are still places virtually untouched, places that you cannot fly or drive to, places unspoiled by mass tourism. When you visit these places, you get a sense of the same virgin magic that ancient nomadic travelers found when they settled there. Add some spice to your life: seek out adventure.',
                            image:'/images/socialmedia/socialmediaimage_adventure.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Adventure',location); 
    },[location]);

    const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 
    const [playEcotourismVideo,setPlayEcotourismVideo] = useState(false); 
    const [playUncruiseVideo,setPlayUncruiseVideo] = useState(false); 

    const [safariCarousel1Index, setSafariCarousel1Index] = useState(0);
    const [safariCarousel2Index, setSafariCarousel2Index] = useState(0);
    const [uncruiseCarousel1Index, setUncruiseCarousel1Index] = useState(0);
    const [uncruiseCarousel2Index, setUncruiseCarousel2Index] = useState(0);
    const [peruCarousel1Index, setPeruCarousel1Index] = useState(0);
    const [peruCarousel2Index, setPeruCarousel2Index] = useState(0);

    const handleCarouselSelect = (section,carousel,selectedIndex) => {
        GoogleAnalyticsEvent("sampleitinerary_carousel_interaction",{section:section,carousel:carousel})
        if(section==='safari'){ 
            if(carousel===1){ 
                setSafariCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setSafariCarousel2Index(selectedIndex); 
            }
        } else if(section==='uncruise'){ 
            if(carousel===1){ 
                setUncruiseCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setUncruiseCarousel2Index(selectedIndex); 
            }
        } else if(section==='peru'){ 
            if(carousel===1){ 
                setPeruCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setPeruCarousel2Index(selectedIndex); 
            }
        }
    };
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
    function getStartingPane(){ 
        if(search!==""){ 
            var querystring = search.substring(1); 
            var querystringarray = querystring.split('&'); 
            var thisParam; 
            for(var i=0;i<querystringarray.length;i++){ 
                thisParam = querystringarray[i].split('='); 
                if(thisParam[0]==='view'){ 
                    return thisParam[1]; 
                    // return Itineraries[thisParam[1]]; 
                }
            }
        } else { 
            return ''; 
        }
    }

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
            <p>Are you itching to get off the couch, out of the house, and even out of the country? When you need to flex your muscles and joints, feel the thrill of adrenaline exploring a far away land, or desire a truly authentic cultural experience, it’s time to explore Adventure Travel opportunities all around the world.</p>

            <Row>
                <Col lg={8}>
                        <p>Seek out the new and the novel, going where few others have trod. Planet Earth is a big, beautiful place—there are still places virtually untouched, places that you cannot fly or drive to, places unspoiled by mass tourism. When you visit these places, you get a sense of the same virgin magic that ancient nomadic travelers found when they settled there. Add some spice to your life: seek out adventure. </p>
                        <ul>
                            <li>Combine the rush of rafting down Japanese rapids with the gentle peace of sipping sake by an open fire at an authentic ryokan—a traditional inn that is the epitome of hospitality.</li>
                            <li>Capture the photographs of a life time after riding in a Land Rover up sketchy mountain trails to the most secluded vistas of South America.</li>
                            <li>Spend your days hiking between villages of Northern India, experiencing the rich and welcoming culture of Buddhist villages and monasteries that have stood there for a thousand years.</li>
                        </ul>
                        <p>Adventure travel is not just about pushing your physical limits—add a day or two of heart-thumping thrill  to an otherwise relaxing resort trip. Many places offer a taste of adrenaline with mile-and-a-half-long zip lines through forest canopies, jungle hikes the majestic waterfalls, off-road and ATV tours where a normal tourist would not trek, or SCUBA diving in underwater biomes few others have seen. </p>
                        <p>Whether you are seeking a new level of sport, enriching your worldview immersed in another culture, or traveling to make the world a better place - Outward Travel is here to help you plan your adventure. Let’s talk about what excites you, what scares you, or what you want out of life—and we’ll help match it with a travel experience you’ll never stop talking about.</p>
                        <ConsultationRequestTrigger color="purple" onClick={()=>{startConsultationRequest('main_content')}} />
                    </Col>
                    <Col lg={4}>
                        <ArticleSidebar id="AdventureSidebar" headline={<>Eco-Tourism</>} color="green">
                            <p>When traveling to remote and unspoiled places, it's important to ensure these lands are respected and preserved. Outward works with partners that are committed to sustainable travel. Learn more about Eco-Tourism in this video:</p>
                            <p className="clickToExpand">
                                <ExpandButton onClick={()=>{setPlayEcotourismVideo(true); GoogleAnalyticsEvent("play_video_ecotourism",{}); }}>
                                    <img src={ecotourism_video_still} alt="EcoTourism Video" id="EcoTourismVideo" width="100%" />
                                </ExpandButton>
                            </p>
                        </ArticleSidebar>
                    </Col>
            </Row> 
            </article>

            <Modal size="lg" aria-labelledby="ecotourism-modal" centered show={playEcotourismVideo} backdrop={true} onHide={()=>{setPlayEcotourismVideo(false)}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Eco-Tourism <small>by <OutboundLink href="https://www.youtube.com/channel/UCYsnlyxYRcq-psj-gpm2YSg" target="_blank">Elisabeth Keuten</OutboundLink></small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="youtube_video" width="766" height="467" src="https://www.youtube.com/embed/fWFSx23DqY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
            </Modal>

            <SampleItineraries id="Adventure_Agendas" 
                sectionHeadline={<>Ideas for an Adventure Vacation</>} 
                color="orange" 
                displayFirst={getStartingPane()} panes={[
                {
                    headline:"A Safari Better Than Any Zoo",
                    linktext:"African Safari",
                    key:"safari",
                    content:<>
                    <p>The night is quiet. The song of a few nocturnal birds wafts in the cooling air. And the sky is bright with a million twinkling stars—more than you’ve ever seen, even when backcountry camping—punctuated by the occasional bright orange fireball of a meteor flying by. You’re in Africa, where animals roam free for thousands of miles and light pollution is nonexistent. You’ve never slept so well, far from the hustle and boredom of life back home.  </p>
                    <p>If this mysterious continent is on your bucket list, you should seriously consider taking in its natural wonder on a guided safari. After landing in Nairobi, Kenya, you’ll depart on a drive through the Masai Mari Game Reserve, seeking out the African Big 5 in their natural habitat: the African elephant, Cape buffalo, rhinoceros, lions, and the most elusive of all: leopards. Your tracker-guide knows the best times and locations to spot these magnificent creatures: today it might be on the tree-dotted plains, tomorrow it will be the watering holes just around that ridge over there. Get close enough for heart-stopping photos, but far enough to avoid disturbing their natural behavior. </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('safari',1,selectedIndex)}} activeIndex={safariCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_1} alt="Photographing elephants in Africa" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_2} alt="Elephants crossing the road on a Safari" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_3} alt="Wild Rhino spotted on an African Safari" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_4} alt="Wild Zebras spotted on an African Safari" />
                        </Carousel.Item>
                    </Carousel>
                    <p>From Kenya you’ll be driven to Tanzania, where you’ll spend several more days searching out the world’s most elegant animals and scenery. On the plains of the unparalleled Serengeti, you’ll spot giraffes and elephants feasting on the flora, and observe the lions, cheetahs, and hyenas prowling for the fauna. It’s the Discovery Channel live and in high definition, and you’re one of the few humans out there to see it today. Explore the Ngorongoro National Park, a game preserve settled in a 12-mile-wide and 2,000-feet-deep volcanic crater, home to an incredible dense population lions, hyenas, cheetahs, jackals, zebra, and the world’s last remaining black rhinos. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('safari',2,selectedIndex)}} activeIndex={safariCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_5} alt="A leopard resting in a tree" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_6} alt="Relaxing at a lodge in Africa" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_7} alt="African safari guide" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_8} alt="Victoria Falls" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_safari_9} alt="Bungee jumping at Victoria Falls" />
                        </Carousel.Item>
                        
                    </Carousel>
                    <p>From these serene vistas you’ll fly to Zimbabwe and board a river cruise ship to see crocodiles, hippos, and an array of birdlife. Visit the awe-inspiring Victoria Falls, and brave the tremendous spray as you walk along a path on the forest edge. The best time to visit the Falls is late winter and spring, when the water pounds over the edge in all its majesty. A few more tours - such as a nighttime trek or a walk through the bush, are available to you, before you’re on to Cape Town for the final leg of your African expedition. </p>
                    <p>You’ve spent two weeks relishing in one of the untouched, unspoiled lands of the world. The fresh air has cleared your body and mind. You feel a kinship with the powerful animals you’ve seen wandering on their daily forage and hunts. You’ve met people and heard their stories, and will take the lessons you’ve learned home with you. And the photos you’ve taken will inspire your friends to break out of their shells, and give in to the pull of nature’s call to far away and beautiful lands. </p>

                    <p className="legalease">African safaris are customized to your interests; Outward Travel can help you plan an amazing safari adventure. Photos are unspecific to any particular journey.</p>
                    
                    <ConsultationRequestTrigger color="red" size="sm" 
                        text={<>Plan a African Safari</>} 
                        onClick={()=>{startConsultationRequest('sample_safari')}} />
                    </>
                },
                {
                    headline:"Push Your Limits in Peru",
                    linktext:"Push Limits in Peru",
                    key:"peru",
                    content:<>
                    <p>The people of the Andes mountains built a civilization on some seriously harsh terrain: steep mountains, thin soil, no wheeled vehicles nor draft animals to help in farming or transportation. This is one of the five “pristine” civilizations of Planet Earth—indigenous to the unique topography and independent of other influences (until the Spanish came to conquer). Survival here was hard work, captured in one of the three moral precepts that governed the culture: do not be lazy. </p>
                    <p>That hearty spirit is one you’ll need to channel for this Peruvian adventure. From the Amazon jungle to the stunning mountaintop citadel of Machu Picchu, you’re departing on this high-altitude expedition through one of the toughest, and most fulfilling, lands of the world. The journey begins with two nights at an eco-lodge in the Amazon rainforest. Along a 7-mile trek, your local guide will give you a crash course on exotic jungle life—from the proper use of medicinal plants to sighting the chattering but shy animals like monkeys, macaws, giant otters, house capybaras, and more. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('peru',1,selectedIndex)}} activeIndex={peruCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_1} alt="Monkeys spotted in the Amazon Rainforest" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_2} alt="Fruit Market in Cusco, Peru" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_3} alt="Cusco, Peru" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_4} alt="Two Peruvian women with Alpaca" />
                        </Carousel.Item>
                    </Carousel>
                    <p>From the natural wonder of the jungle you’ll arrive in the heart of the Incan Empire, the city of Cusco. At an altitude of 11,000 feet, you’ll need to acclimate here for a couple days before heading on up even higher. Explore the city that blends ancient, colonial, and modern architecture, taste the decadent local chocolate, and visit the Sacred Valley where you can learn from a community that still follows many of the old traditions.</p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('peru',2,selectedIndex)}} activeIndex={peruCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_5} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_6} alt="Hiking on the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_7} alt="Hiking with Porters on the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_8} alt="A llama at Machu Picchu" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_9} alt="A view of Machu Picchu" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_10} alt="A view of Machu Picchu with a Rainbow" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_peru_11} alt="Two people celebrating reaching Machu Picchu" />
                        </Carousel.Item>
                    </Carousel>

                    <p>Then it’s on to the four-day hike and camping expedition along the Classic Inca Trail—you’ll be trekking as high as 13,000 feet before reaching the once-hidden city of Machu Picchu. It was built more than 500 years ago as a royal estate for the Incan Emperors before being abandoned during the Spanish conquests (though the Spanish never found it). To preserve the site, tours are limited to a prescribed route, but this place is the pinnacle of Incan history and is one of the wonders of the world that should definitely be on your bucket list. </p>
                    <p>This is a journey that will push your body and expand your mind—perfect for those who seek an adventure that defined everyday life of an ancient people. And if you dream of Machu Picchu without the long hike, there’s also a train that can bring you right up to it. Either way, you’ll marvel at what the Incans accomplished with nothing more than their bare hands. </p>

                    <p className="legalease">Your adventure to Peru can be customized to your interests and fitness level, let Outward Travel help you plan your trek to Machu Picchu. Photographs are unspecific to any specific journey.</p>
                     <ConsultationRequestTrigger color="red" size="sm" 
                    text={<><span className="hidden-xs hidden-sm">Plan a</span>
                             <span className="hidden-md hidden-lg hidden-xl hidden-xxl">Let's</span> Trek to Machu Picchu</>} 
                    onClick={()=>{startConsultationRequest('sample_peru')}} />
                    </>
                },
                {
                    headline:"Take an (Un)Cruise To Alaska",
                    linktext:"Alaska by Waterway",
                    key:"uncruise-alaska",
                    content:<>
                    <p><b>Wait!</b> Don’t leave the page yet—the idea of a cruise vacation doesn’t excite many Adventure Travelers, but this is not that kind of cruise. So many of the world’s wonders are best accessed by sea, and this adventure through Alaska Glacier Country is one of them. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('uncruise',1,selectedIndex)}} activeIndex={uncruiseCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <VideoButton  onClick={()=>{setPlayUncruiseVideo(true); GoogleAnalyticsEvent("play_video_uncruise",{}); }}><img className="d-block w-100" src={itinerary_uncruise_vid} alt="Video of the the Uncruise Alaska Glacier Adventure" /></VideoButton>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_1} alt="Exterior of the Wilderness Adventurer, by Uncruise" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_2} alt="View Glaciers from the bow of the Wilderness Adventurer, by Uncruise" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_3} alt="Gourmet food onbaord the Wilderness Adventurer" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_4} alt="Cabin onboard the Wilderness Adventurer" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_5} alt="Skiff adventure in Alaska" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_ship_6} alt="Kayak and Skiff watching a whaleshark in Alaska" />
                        </Carousel.Item>
                    </Carousel>
                    <p>This is no ordinary, mass-market cruise ship. With only 60 other passengers on board and only 30 cabins, you’re living a week at sea like adventurers of old who first explored these waters. It’s not a city-ship, it’s a cozy home at sea while you’re focused on the natural wonder around you. With a high crew-to-passenger ratio, the on-board team knows your name and wants to ensure you have what you need. Hand-crafted cuisine and an open bar are all included. Take one of the kayaks or paddleboards out for a spin before relaxing in the hot tub on the top deck. Peer below the surface with the underwater camera and hydrophone. All of it means  you’ll be able to go at your own pace throughout the journey. </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('uncruise',2,selectedIndex)}} activeIndex={uncruiseCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_1} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_2} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_3} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_4} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_5} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_uncruise_land_6} alt="Part of the Inca Trail" />
                        </Carousel.Item>
                    </Carousel>

                    <p>From Juneau your ship will depart for the northern stretches of the Alaskan Wilderness. Each day you’ll have the chance to explore—off-ship. Glide around bays snug in a kayak. Go with a small group on a skiff to watch sea lions play on an rocky outcropping. Take a forest hike guided by naturalist guides who can share more insight into biology, plantlife, and geology than you ever got in school. Watch glaciers calve into the water and keep the binoculars handy to spy all manner of wildlife—from bears to mountain goats, puffins to eagles, seals to whales feasting in the icy waters. </p>

                    <p className="legalease">Photographs provided by UnCruise Adventures.</p>
                     <ConsultationRequestTrigger color="red" size="sm" 
                    text={<>Explore Alaska by Ship</>} 
                    onClick={()=>{startConsultationRequest('sample_uncruise')}} />
                    </>
                },
            ]} />
            


            <Modal size="lg" aria-labelledby="uncruise-video" centered show={playUncruiseVideo} backdrop={true} onHide={()=>{setPlayUncruiseVideo(false)}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Take an UnCruise to Alaska
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="youtube_video" width="766" height="467" src="https://www.youtube.com/embed/DHTURAfmrk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
            </Modal>

        </Tier3Layout>
    </main>
  )
}


export default Adventure;

